import Vue from "vue/dist/vue.esm";
import logos from './mixin/logos';

// 各くじ毎のリストを表示する
var LatestBoxes = Vue.extend({
  mixins: [logos],
  template: `
  <div :class="{'loading-circular-spinner': !isLoaded}">
    <transition-group class="list-box-half list-box-half-flex" tag="ul" enter-active-class="animated fadeIn">
      <li class="box pos-relative" v-for="box in latestBoxes" :key="box.id">
        <div class="box-header text-bold text-oneline" :style="paddingForYamawake(box.yamawake_price)">
          <img :src="logos(getLogoType(box.box_type))" :width="adjustImgWidth(box.box_type)" class="vam" />
          {{ displayGroupCode(box) ? '【' + box.group_code + '】' : '' }} {{box.open_day}} {{box.race_name}}
        </div>
        <div class="box-body">
          <div class="text-center">
            <h3 class="mb10 text-oneline">
              <div v-if="(smartStatus(box) === 1) || (smartStatus(box) === 2 && box.on_hit_return === false)">
                <span class="text-bold text-medium">{{ box.yamawake_price ? '' : '現在の払戻可能総額' }}</span>
                <span class="text-bold text-red-highlight">{{displayPrizeMoney(box)}}円</span>
              </div>
              <div v-else>
                <span class="text-bold text-medium">{{ displayPrizeTitle(box) }}</span>
                <span class="text-bold text-red-highlight">{{ displayPrizeResult(box) }} {{ box.hit_return_for_top.multi ? '〜' : '' }}</span>
              </div>
            </h3>
          </div>
          <p class="alert alert-inverse-base text-center" v-if="smartStatus(box) === 1">
            [{{ box.vel_name }}] 現在開催中！ 締切：{{box.sale_to_at_string}}
          </p>
          <p class="alert alert-inverse-gray-dark text-center text-ink" v-else>
            [{{ box.vel_name }}] {{ smartStatus(box) === 3 ?  'レースは中止になりました。' : '販売終了' }}
          </p>
        </div>
        <div class="box-footer clearfix">
          <p class="fl-left text-bold mt10"> {{ smartStatus(box) === 1 ? 'トータル発売口数：' + box.current_total_kuchi_count : '的中口数：' + getHitReturnVotes(box) }}</p>
          <a :href="getJushouPath(box)" class="btn btn-arrow-right btn-positive fl-right" v-if="smartStatus(box) === 1">投票する</a>
          <a :href="jushouResultsURL(box)" class="btn btn-arrow-right btn-gray-darker fl-right" v-else>レース経過</a>
        </div>
        <div class="pos-top-right" v-if="!box.end_of_sale_question && box.yamawake_price > 0" style="top: -10px; right: 5px;">
          <!-- <img src="/img/common/img_yamawake.png" alt="山分けチャンス" > -->
        </div>
      </li>
    </transition-group>
  </div>
  `,
  data: function() {
    return {
      latestBoxes: [],
      openDay: "",
      isLoaded: true,
    };
  },
  mounted: function() {
    this.openDay = document
      .getElementById("body")
      .getAttribute("data-keirin_open_day");
  },
  methods: {
    fetchLatestBoxes: function(box_type) {
      this.isLoaded = false;
      this.latestBoxes = [];

      $.ajax({
        url: "/api/keirin_jushous/list_with_box_type",
        data: {
          box_type: box_type,
        },
      }).done(
        function(res) {
          this.latestBoxes = res;
          this.isLoaded = true;
        }.bind(this)
      );
    },
    adjustImgWidth: function(box_type) {
      if (box_type === "dokanto") {
        return "25%";
      } else if (box_type === "dokanto_4two") {
        return "31%";
      } else {
        return "15%";
      }
    },
    // 山分けプライスがあったらヘッダーにpaddingを与える
    paddingForYamawake: function(yamawake_price) {
      if (yamawake_price > 0) {
        return "padding-right: 64px;";
      } else {
        return false;
      }
    },
    // 各くじのurlを生成するための関数、boxのobjectを渡すことで
    // くじの形式毎にurlを生成
    getJushouPath: function(obj) {
      if (obj.kuji_question) {
        return "/keirin_jushous/kuji/" + obj.id + "/price_selection";
      } else {
        return "/keirin_jushous/selection/" + obj.id + "/price_selection";
      }
    },
    displayGroupCode: function(box) {
      if (
        box.box_type == "chariloto" ||
        box.box_type == "chariloto_select" ||
        box.box_type == "chariloto_5"
      ) {
        return true;
      } else {
        return false;
      }
    },
    displayPrizeMoney: function(box) {
      if (box.end_of_sale_question) {
        return chari.util.convertAmountKanji(box.current_carryover);
      } else {
        if (box.yamawake_price > 0) {
          return "山分け" + chari.util.convertAmountKanji(box.yamawake_price);
        } else {
          return chari.util.convertAmountKanji(box.current_carryover);
        }
      }
    },
    displayPrizeTitle: function(box) {
      if (box.hit_return_for_top.type == "hazure") {
        return "現在のキャリーオーバー";
      } else if (box.hit_return_for_top.type == "tokubarai") {
        return "特払い";
      } else if (box.hit_return_for_top.type == "henkan") {
        return "全返還";
      } else {
        return "払戻金（１口あたり）";
      }
    },
    displayPrizeResult: function(box) {
      if (box.hit_return_for_top.type == "hazure") {
        return chari.util.convertAmountKanji(box.current_carryover) + "円";
      } else if (box.hit_return_for_top.type == "tokubarai") {
        return box.hit_return_for_top.amount + "円";
      } else if (box.hit_return_for_top.type == "henkan") {
        return "";
      } else {
        var result =
          chari.util.convertAmountKanji(box.hit_return_for_top.amount) + "円";

        return result;
      }
    },
    getHitReturnVotes: function(box) {
      if (box.on_hit_return) {
        return box.hit_return_for_top.hit_vote;
      } else {
        return "--";
      }
    },
    jushouResultsURL: function(box) {
      var q =
        box.box_type === "dokanto" || box.box_type === "dokanto_4two"
          ? "?dokanto=1"
          : "#" + box.box_type;

      return "/keirin/results/" + box.vel_code + "/" + box.open_day + "/" + q;
    },
    smartStatus: function(box) {
      if (box.on_abort) {
        return chari.const.KEIRIN_SMART_STATUS.cancel; // 中止：3
      } else if (box.end_of_sale_question) {
        return chari.const.KEIRIN_SMART_STATUS.finish; // 終了：2
      } else {
        return chari.const.KEIRIN_SMART_STATUS.open; //  開催中：1
      }
    },
    getLogoType: function(box_type) {
      switch(box_type) {
        case 'chariloto':
          return 'logoChariloto';
        case 'chariloto_select':
          return 'logoCharilotoSelect';
        case 'chariloto_5':
          return 'logoChariloto5';
        case 'chariloto_3':
          return 'logoChariloto3';
        case 'dokanto':
          return 'logoDokanto';
        case 'dokanto_4two':
          return 'logoDokanto4two';
      }
    },
  },
});

export default {
  LatestBoxes,
};
