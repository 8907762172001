import Vue from "vue/dist/vue.esm";

/**
 * 競輪、オートレースの通常賭式の一覧表示コンポーネント
 * TOPページや競輪TOP、オートTOPの一覧表示部分で使用
 */

import mixinBox from "./mixins/mixin_box";

var boxHelper = mixinBox.boxHelper;

var nanakakeBoxes = Vue.extend({
  template: `
    <div :class="{'loading-circular-spinner': !isLoaded}">
      <transition-group class="list-box-half list-box-half-flex" tag="ul" enter-active-class="animated fadeIn" appear v-if="hasBox">
        <li class="box" v-for="(box, index) in sortedBoxes" :key="box.current_box.id"> 
          <div class="box-body">
            <div class="g-layer">
              <div class="g-unit-1-3">
                <div class="text-center pos-relative">
                  <div class="text-bold text-xlarge mb0">{{box.current_box.vel_name}}</div>
                  <div>
                    <img :src="icons(getIconType(box.current_box.open_grade_code.toUpperCase()))" width="25" class="vam" />
                    <img :src="icons(getIconType(box.current_box.night_race_type.toUpperCase()))" width="25" class="vam" v-if="box.current_box.night_race_type != 'default'" />
                    <img :src="icons('iconGirls')" width="25" class="vam" v-if="box.is_girls" />
                  </div>
                  <div class="text-bold text-small">
                    <span class="text-bold text-small">{{ box.current_box.spent_days_string }}{{ box.current_box.rehold_string }}</span>
                    {{ boxStatusLabel(box) }}
                  </div>
                  <div class="text-bold text-small" v-if="box.smart_status === 1">締め切り {{box.current_box.sale_to_at_string}}</div>                  
                </div>
              </div><!-- .g-unit -->
              <div class="g-unit-1-3 pr5">
                <a :href="voteURL(box.current_box.open_day, box.current_box.vel_code, box.current_box.race_num)" class="btn btn-positive btn-arrow-right btn-compact btn-block mb5" v-if="isOpen(box)" >
                  <div v-for="(reporter, reporter_index) in reporters(box)">
                    <span class="pos-top-left" :style="reporter_style(reporter, reporter_index)">{{reporter.reporter_name}}</span>
                  </div>
                  <span v-if="isTeppan(box)" class="ico-notice pos-top-left" style="top: -8px; left: -8px;"></span>
                  {{ gameType === 1 ? '競輪投票' : 'オート投票' }}
                </a>
                <!-- <a :href="resultsURL(box.current_box.open_day, box.current_box.vel_code)" class="btn btn-arrow-right btn-compact btn-block mb5" :class="gradeTypeDarkClass(box.current_box.open_grade_code.toUpperCase())" style="color: black; font-weight: bold;" v-else>レース結果</a> -->
                <a :href="voteURL(box.current_box.open_day, box.current_box.vel_code, 1)" class="btn btn-arrow-right btn-compact btn-block mb5" :class="gradeTypeDarkClass(box.current_box.open_grade_code.toUpperCase())" style="color: black; font-weight: bold;" v-else>レース結果
                  <div v-for="(reporter, reporter_index) in reporters(box)">
                    <span class="pos-top-left" :style="reporter_style(reporter, reporter_index)">{{reporter.reporter_name}}</span>
                  </div>
                </a>  
                <div class="g-layer">
                  <div class="g-unit-1-2">
                    <a :href="raceTableURL(box.current_box.open_day, box.current_box.vel_code)" class="btn btn-bordered btn-block btn-slim">
                      出走表
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                    </a>
                  </div>
                  <div class="g-unit-1-2 pl5">
                    <a :href="oddsTableURL(box.current_box.open_day, box.current_box.vel_code, box.current_box.race_num)" class="btn btn-bordered btn-slim btn-block">
                      オッズ
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div><!-- .g-unit -->
              <div class="g-unit-1-3">
                <a :href="jushouURL(box)" class="btn btn-positive btn-arrow-right btn-compact btn-block text-nowrap mb5" v-if="targetDir == 'keirin' && box.has_jushou && box.sale_jushou" :class="[gradeTypeLightClass(box.current_box.open_grade_code.toUpperCase())] "  >競輪くじ投票</a>
                <a :href="jushouURL(box)" class="btn btn-gray-darker btn-arrow-right btn-compact btn-block text-nowrap mb5" v-if="targetDir == 'keirin' && box.has_jushou && box.sale_jushou == false" >競輪くじ結果</a>                
                <a :href="liveURL(box.current_box.vel_code)" class="btn btn-gray-darker btn-arrow-right btn-compact btn-block" target="_blank">
                  <i class="fa fa-video-camera" aria-hidden="true"></i>
                  LIVE
                </a>
              </div>
            </div><!-- .g-layer -->
          </div><!-- .box-body -->
        </li><!-- .box -->
      </transition-group>
      <p class="pv50 ph20" v-else-if="isLoaded && !hasBox">本日開催のレースはありません。</p>
    </div>
  `,
  data: function() {
    return {
      boxes: [],
      isLoaded: false,
    };
  },
  created: function() {
    // if (this.gameType == chari.const.GAME_TYPE.keirin) {
    //   this.open_day = document.getElementById('body').getAttribute('data-keirin_open_day');
    // } else {
    //   this.open_day = document.getElementById('body').getAttribute('data-auto_open_day');
    // }

    if (this.gameType == chari.const.GAME_TYPE.keirin) {
      this.fetchKeirinBoxes(this.open_day);
    } else {
      this.fetchAutoBoxes(this.open_day);
    }
  },
  mixins: [boxHelper],
  methods: {
    reporters: function(box) {
      var reporters = [];

      box.reporter_race_nums.forEach(function(arrays) {
        arrays.forEach(function(array) {
          var obj = {
            reporter_name: array.reporter_name.charAt(0),
            color: array.color
          };
          if (!reporters.some(r => r.reporter_name === obj.reporter_name && r.color === obj.color) && reporters.length <= 5) {
            reporters.push(obj);
          }
        });
      });
      return reporters;
    },
    reporter_style: function(reporter, reporter_index) {
      var reporter_style = "top: -8px; left: " + (16 * reporter_index) + "px; width: 16px; height: 16px; border-radius: 50%; font-weight: normal; color: white; background: " + reporter.color + ";";

      return reporter_style;
    },
    fetchAutoBoxes: function() {
      this.isLoaded = false;
      this.initBoxes();

      chari.api.getAutoBoxes(
        {
          open_day: this.open_day,
        },
        function(res) {
          this.boxes = res;
          this.isLoaded = true;
        }.bind(this)
      );
    },
    fetchKeirinBoxes: function() {
      this.isLoaded = false;
      this.initBoxes();

      chari.api.getKeirinBoxes(
        {
          open_day: this.open_day,
        },
        function(res) {
          this.boxes = res;
          this.isLoaded = true;
        }.bind(this)
      );
    },
    initBoxes: function() {
      this.boxes = [];
    },
    displayDeadLine: function(date) {
      return chari.util.extractHoursMinutes(date);
    },
    gameTypePath: function() {
      if (this.gameType == chari.const.GAME_TYPE.keirin) {
        return "/keirin";
      } else {
        return "/auto";
      }
    },
    resultsURL: function(open_day, vel_code) {
      return (
        this.gameTypePath() + "/results/" + vel_code + "/" + open_day + "/"
      );
    },
    raceTableURL: function(open_day, vel_code) {
      return (
        this.gameTypePath() + "/athletes/" + open_day + "/" + vel_code + "/"
      );
    },
    oddsTableURL: function(open_day, vel_code, race_num) {
      var raceNumQuery = race_num ? race_num : "";
      return (
        this.gameTypePath() +
        "/odds/" +
        open_day +
        "/" +
        vel_code +
        "/" +
        raceNumQuery
      );
    },
    voteURL: function(open_day, vel_code, race_num) {
      var raceNumQuery = race_num ? race_num : "";
      return (
        this.gameTypePath() +
        "/" +
        open_day +
        "/" +
        vel_code +
        "/?race_num=" +
        raceNumQuery
      );
    },
    jushouURL: function(box) {
      if (box.has_jushou && box.sale_jushou) {
        return "/keirin_jushous";
      } else {
        return `/keirin/results/${box.current_box.vel_code}/${box.current_box.open_day}/`;
      }
    },
    liveURL: function(vel_code) {
      return chari.util.getLiveUrl(vel_code, false, this.gameType);
    },
    isTeppan: function(box) {
      if (box.teppan_race_nums.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    isOpen: function(box) {
      if (box.smart_status === 1) return true;
      return false;
    },
    boxStatusLabel: function(box) {
      // 場全体のステータスを見てから現在開催レースのステータスを見る
      if (box.vel_smart_status === 1) {
        // 要確認：current_boxは最終レース以外販売中のものしかこない想定
        if (box.smart_status != 1)
          return this.smartStatusLabel(box.smart_status, this.gameType);
        if (box.current_box.is_invalid)
          return box.current_box.race_num + "R" + "中止";
        return box.current_box.race_num + "R" + "開催中";
      } else if (box.vel_smart_status === 2) {
        return "販売終了";
      } else {
        return "開催中止";
      }
    },
  },
  computed: {
    open_day: function() {
      if (this.gameType == chari.const.GAME_TYPE.keirin) {
        return document
          .getElementById("body")
          .getAttribute("data-keirin_open_day");
      } else {
        return document
          .getElementById("body")
          .getAttribute("data-auto_open_day");
      }
    },
    targetDir: function() {
      if (this.gameType == chari.const.GAME_TYPE.keirin) {
        return "keirin";
      } else {
        return "auto";
      }
    },
    hasBox: function() {
      if (this.boxes.length) {
        return true;
      }

      return false;
    },
    sortedBoxes: function() {
      var boxes = this.boxes;
      var priority;

      if (this.gameType === 1) {
        // 競輪の場合
        priority = { GP: 6, G1: 5, G2: 4, G3: 3, F1: 2, F2: 1 };
      } else {
        // オートの場合
        priority = { sg: 4, g1: 3, g2: 2, normal: 1 };
      }

      // gradeの高い順でsort、gradeが同じ場合はvel_codeの若い順
      boxes.sort(function(a, b) {
        var agrade = a.current_box.open_grade_code;
        var bgrade = b.current_box.open_grade_code;
        var avel = parseInt(a.current_box.vel_code);
        var bvel = parseInt(b.current_box.vel_code);

        if (!agrade && !bgrade) return 0;
        if (!bgrade) return -1;
        if (!agrade) return 1;

        if (priority[agrade] > priority[bgrade]) {
          return -1;
        } else if (priority[agrade] < priority[bgrade]) {
          return 1;
        } else {
          if (avel < bvel) return -1;
          if (avel > bvel) return 1;

          return 0;
        }
      });

      return boxes;
    },
  },
  watch: {
    gameType: {
      handler: function() {
        if (this.gameType == chari.const.GAME_TYPE.keirin) {
          this.fetchKeirinBoxes(this.open_day);
        } else {
          this.fetchAutoBoxes(this.open_day);
        }
      },
    },
  },
});

export default {
  nanakakeBoxes,
};
